import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero';
import Section from '../components/Section';
import { MarkdownContent } from '../components/Content';

const MarkdownPage = ({ title, heroImage, heroContent, content }) => {
  return (
    <>
      <Hero image={heroImage} />
      <Section colorScheme="white">
        <div className="container flex flex-col md:flex-row py-12 gap-x-12">
          <div className={`pb-4  ${heroContent ? 'md:w-1/3' : ''}`}>
            <h2>
              <span className="bg-theme-underline">{title}</span>
            </h2>
          </div>
          {heroContent && (
            <div className="md:w-2/3 text-xl">
              <MarkdownContent content={heroContent} />
            </div>
          )}
        </div>
      </Section>
      <Section overlaySide="left" overlayLength="large">
        <div className="container py-12 md: py-24">
          <MarkdownContent content={content} />
        </div>
      </Section>
    </>
  );
};

MarkdownPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  heroContent: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default MarkdownPage;
