import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import MarkdownPage from '../templates/MarkdownPage';
import Layout from '../components/Layout';

const PrivacyPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.privacyPage;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <MarkdownPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          content={page[pageContext.locale].body || page[defaultLocale].body}
        />
      </Layout>
    </LocaleProvider>
  );
};

PrivacyPageTemplate.propTypes = {
  data: PropTypes.shape({
    privacyPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PrivacyPageTemplate;

export const pageQuery = graphql`
  query PrivacyPage($id: String!) {
    privacyPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          title
          hero
          body
        }
        de {
          title
          hero
          body
        }
      }
    }
  }
`;
